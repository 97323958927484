<template>
  <multi-select
    class="select-company-dropdown"
    track-by="id"
    label="name"
    open-direction="bottom"
    :placeholder="dropdownPlaceholder"
    :options="sortedCompanies"
    @select="handleSelect"
  >
    <template slot="option" slot-scope="{ option }">
      <div v-if="updateAvailable(option)" class="update-available">
        <feather-icon class="alert-circle" type="alert-circle" />
        {{ option.name }}
      </div>
      <div v-else>
        {{ option.name }}
      </div>
    </template>
  </multi-select>
</template>
<script>
import { sortArrayByKey } from '@/helpers'
import { mapGetters } from 'vuex'

export default {
  name: 'CompanyMultiSelect',
  components: {
    MultiSelect: () => import('vue-multiselect'),
    FeatherIcon: () => import('@/components/shared/FeatherIcon'),
  },
  props: {
    companies: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('dashpanel', [
      'isEntityTypeDomains',
    ]),
    dropdownPlaceholder() {
      return `Select an existing ${this.isEntityTypeDomains ? 'domain' : 'business'}`
    },
    sortedCompanies() {
      return sortArrayByKey(this.companies)
    },
  },
  methods: {
    updateAvailable(company) {
      return company.stageline?.route?.show_alert
    },
    handleSelect(option, _id) {
      this.$emit('company-selected', option)
    },
  },
}
</script>
<style lang="scss" scoped>
.select-company-dropdown {
  cursor: pointer;
  margin-top: unset !important;
  ::v-deep .multiselect__select {
    margin-top: 10px;
  }
  ::v-deep .multiselect__tags {
    border-radius: $stageline-primary-border-radius !important;
    border: solid 1px #b7b7b7;
    height: 60px;

    .multiselect__placeholder,
    .multiselect__input {
      font-size: 16px !important;
      padding-top: 0.5em;
      padding-left: 1em;
    }
  }
}
</style>
